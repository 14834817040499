import { Injectable } from "@angular/core";

import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { END_POINT } from "../core/constants/constants";
import { HttpService } from "../core/http/http.service";

@Injectable({
  providedIn: "root",
})
export class GeneralService {
  constructor(private http: HttpService) {}

  public login(loginRequest): Observable<any> {
    return this.http.get(END_POINT.login, loginRequest);
  }

  public loginToken(data: { token; platform; extraData }): Observable<any> {
    return this.http.getWithoutToken(END_POINT.login + "/token", data);
  }

  public getJugadoresSinValidar(): Observable<any> {
    return this.http.get(END_POINT.jugadoresSinValidar);
  }

  /**
   *
   * V3
   */

  public getFichajesBy(params = {}) {
    return this.http.get(END_POINT.V3.fichajes, params);
  }

  public getJugadoresSinValidarV2() {
    return this.http.get(END_POINT.FICHAES_V2.getPendientes);
  }

  public getSignedUrl(url, type) {
    return this.http.get(END_POINT.AWS.signedUrl + `?url=${url}&type=${type}`);
  }
  public getJugadorSinValidarV2(idFichaje) {
    return this.http.get(END_POINT.FICHAES_V2.getPendientes + "/" + idFichaje);
  }

  public getEquipos(): Observable<any> {
    return this.http.get(END_POINT.equipos);
  }

  public getCategorias(): Observable<any> {
    return this.http.get(END_POINT.categorias);
  }

  public getFuncionarios(): Observable<any> {
    return this.http.get(END_POINT.V3.funcionarios);
  }

  public filtrarUsuarios(data): Observable<any> {
    return this.http.post(END_POINT.filtarJugadores, data);
  }

  public marcarPago(jugadoresId, pago, comprobante): Observable<any> {
    return this.http.post(END_POINT.marcarPago, {
      jugadoresId,
      pago,
      comprobante,
    });
  }

  public exportarSvg(data): Observable<any> {
    return this.http.postCSV(END_POINT.exportarJugadores, data);
  }

  public getCantidadJugadoresSinValidar(): Observable<any> {
    return this.http.get(END_POINT.cantidadJugadoresSinValidar).pipe(
      //t
      map((x: any) => {
        return {
          cant: x.cant,
        };
      })
    );
  }

  public getCategories(): Observable<any> {
    return this.http.get("/category");
  }

  public getCategoriasRecategorizacion(): Observable<any> {
    return this.http.get(END_POINT.recategorizacion);
  }

  public addCategoriaRecategorizacion(idCategoria): Observable<any> {
    return this.http.post(END_POINT.recategorizacion + "/categorias ", {
      idCategoria,
    });
  }

  public deleteCategoriaRecategorizacion(idCategoria): Observable<any> {
    return this.http.post(END_POINT.recategorizacion + `/deleteCategoria`, {
      idCategoria,
    });
  }

  public getRecategorizacionesList(): Observable<any> {
    return this.http.get(END_POINT.recategorizacionLogger);
  }

  public getTeamDataWeb(): Observable<any> {
    return this.http.get(END_POINT.APP_CLUBES.getTeamUsersWeb);
  }

  public getTotalFichajesBorrados() {
    return this.http.get(END_POINT.fichajesBorrados);
  }

  public getTotalFichajes() {
    return this.http.get(END_POINT.fichajesTotales);
  }

  public getTotalCountFichajesBorrados() {
    return this.http.get(END_POINT.fichajesCountBorrados);
  }

  public getTotalCountFichajes() {
    return this.http.get(END_POINT.fichajesCountTotales);
  }

  public getNotificacionesEnviadas() {
    return this.http.get(END_POINT.notificaciones);
  }

  public getUserWithPushID() {
    return this.http.get(END_POINT.usersByPush);
  }

  public enviarNotificacion({ userId, mensaje }) {
    return this.http.post(END_POINT.notificaciones, { userId, mensaje });
  }

  public markAsDeshabilitado(idUsers) {
    return this.http.post(END_POINT.HABILITADOS_V2.deshabilitarJugadores, {
      idUsers,
    });
  }

  public markAsHabilitado(idUsers) {
    return this.http.post(END_POINT.HABILITADOS_V2.habilitarJugadores, {
      idUsers,
    });
  }
}
