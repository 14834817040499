import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

import { Observable, of, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { LocalstorageService } from "../general/localstorage.service";
import { TOKEN_HEADER, TOKEN_PREFIX } from "../constants/constants";
import { AuthenticationService } from "../auth/authentication.service";
import { AngularFireAuth } from "@angular/fire/auth";

@Injectable({
  providedIn: "root",
})
export class HttpInterceptorService implements HttpInterceptor {
  constructor(
    public authFb: AngularFireAuth,
    private auth: AuthenticationService,
    private localStorageService: LocalstorageService,
    private router: Router
  ) {}

  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.url.includes("amazonaws.com")) {
      // Si es una solicitud a S3, la pasa sin el encabezado 'Authorization'
      return next.handle(request);
    }

    const token = this.localStorageService.getItem(TOKEN_HEADER);
    let headers = request.headers;
    if (token)
      headers = headers.append(TOKEN_HEADER, `${TOKEN_PREFIX}${token}`);
    request = request.clone({ headers });
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 || error.status === 403) {
          return this.handle401Error(request, next);
        } else return throwError(error);
      })
    );
  }

  private handle401Error(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Promise<HttpEvent<any>> {
    return new Promise((resolve, reject) => {
      this.authFb.authState.subscribe((user) => {
        if (user) {
          user.getIdToken(true).then((token) => {
            if (token) {
              let headers = req.headers;
              headers = headers.delete(TOKEN_HEADER);
              headers = headers.append(TOKEN_HEADER, `${TOKEN_PREFIX}${token}`);
              const authReq = req.clone({ headers });
              this.localStorageService.setItem(TOKEN_HEADER, token);
              return of(next.handle(authReq).toPromise().then(resolve, reject));
            }
          });
        } else {
          reject("No User Logged In");
          this.auth.logOut();
          this.router.navigate(["/login"]);
        }
      });
    });
  }
}
