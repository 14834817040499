// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  baseUrl: "https://api-test.futsala.ar",
  titleApp: "FUTSALA",
  firebase: {
    apiKey: "AIzaSyAD-3agbm-ANDFWSOxzK4BHjmm9QMN8SIY",
    authDomain: "primeraligalifa-clubes.firebaseapp.com",
    projectId: "primeraligalifa-clubes",
    storageBucket: "primeraligalifa-clubes.appspot.com",
    messagingSenderId: "1007200625163",
    appId: "1:1007200625163:web:f246e2d197a1e0631b5d0e",
    measurementId: "G-1990CKMT9N",
  },
};
