import { Component, OnInit } from "@angular/core";
import { AuthenticationService } from "app/core/auth/authentication.service";
import { PERMISOS } from "app/core/constants/permisos.constants";

declare const $: any;
declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
  permiso?: string;
  params?: any;
}
export const ROUTES: RouteInfo[] = [
  { path: "/dashboard", title: "Dashboard", icon: "dashboard", class: "" },
  {
    path: "/noticias",
    title: "Noticias",
    icon: "person",
    class: "",
    permiso: PERMISOS.APPCLUBES_BO_NOTICIAS,
  },
  {
    path: "/jugadores",
    title: "Jugadores",
    icon: "person",
    class: "",
    permiso: PERMISOS.APPCLUBES_BO_JUGADORES,
  },
  // { path: '/notifications', title: 'Notificaciones',  icon:'notifications', class: '',permiso:PERMISOS.APPCLUBES_BO_NOTIFICACIONES },
  {
    path: "/accesos",
    title: "Accesos",
    icon: "dashboard",
    class: "",
    permiso: PERMISOS.APPCLUBES_BO_ACCESOS,
  },
  {
    path: "/fichaje/players",
    title: "Fichaje Jugadores",
    icon: "library_books",
    class: "",
    permiso: PERMISOS.APPCLUBES_BO_FICHAJE,
  },
  {
    path: "/fichaje/funcionarios",
    title: "Fichaje Administrativos",
    icon: "library_books",
    class: "",
    permiso: PERMISOS.APPCLUBES_BO_FICHAJE,
  },
  {
    path: "/recategorizacion",
    title: "Recategorizacion",
    icon: "library_books",
    class: "",
    permiso: PERMISOS.APPCLUBES_BO_RECATEGORIZACION,
  },
  {
    path: "/configuracion",
    title: "Configuración",
    icon: "library_books",
    class: "",
    permiso: PERMISOS.APPCLUBES_BO_CONFIGURACION,
  },
  {
    path: "/usuarios",
    title: "Configuración Usuarios",
    icon: "library_books",
    class: "",
    permiso: PERMISOS.ADM_USUARIOS,
  },
];

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"],
})
export class SidebarComponent implements OnInit {
  menuItems: any[];

  constructor(private auth: AuthenticationService) {}

  logout() {
    this.auth.logOut();
  }
  ngOnInit() {
    this.menuItems = ROUTES.filter((menuItem) => {
      if (menuItem.permiso)
        return this.auth.permisos.includes(menuItem.permiso);
      return true;
    });
  }
  isMobileMenu() {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  }
}
